/**

 * @package     Joomla.Site

 * @subpackage  B4 theme

 *

 * @copyright   Copyright (C) 2017 UAB IT Solutions, Inc. All rights reserved.

 * @license     NON-FREE. Contact info@itsolutions.lt for licensing details

 */



(function($){



	$(document).ready(function(){



		var phone_link1 = $( 'a[href*="+37067117482"]' );

		var phone_link2 = $( 'a[href*="+37061406300"]' );

		phone_link1.on('click', function(){

			gtag('event', 'skambutis', {

			  'method': 'Google'

			});

		});

		phone_link2.on('click', function(){

			gtag('event', 'skambutis', {

			  'method': 'Google'

			});

		});



		//animate on page load

		animateElements();



		$(window).on('scroll', function(){

			//animate on scroll

			animateElements();



		});



		$(".icon_deeper").on('click', function(event){

			event.preventDefault();

			$(this).closest('li.deeper').children('ul.nav-child').toggle();



		});



		function animateElements(){

			var window_ob = $(this);



			$('.animate-scroll').each(function(){

				var element = $(this);

				var animate_class = $(this).data('animate');

				if(isElementInViewport(element)) {

					//element.delay(750).queue(function(){

						element.addClass(animate_class).removeClass('invisible');

					//});

				}

			});

		}



		function isElementInViewport (el) {

			var height = el.height();

			if (typeof jQuery === "function" && el instanceof jQuery) {

			el = el[0];

			}



			if (typeof el !== 'undefined') {

		        var rect = el.getBoundingClientRect();

		        var window_ob = $(this);

		        var bottom_of_window = window_ob.scrollTop() + window_ob.height();

		       //console.log(bottom_of_window);

		       // console.log((bottom_of_window - (window_ob.height() / 4)));

		       //  if (rect.top >= 0 && rect.left >= 0 && rect.top <= (bottom_of_window - (window_ob.height() / 4))){

		       //  	 //console.log(rect.top >= 0);

		       //  	return true;

		       //  }



		        //return false;

				return (

					rect.top >= 0 &&

					rect.left >= 0 &&

					rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + (height / 2)

				);

		    }



		}



		$(".search-button").mouseover(function(){

	        $(".form-control.search-field").removeClass("non-visible-search");

	        $(".form-control.search-field").addClass("visible-search");



	    });

	    $(".search-button").mouseout(function(){

	    	if ($(".form-control.search-field").val().length === 0){

		        $(".form-control.search-field").addClass("non-visible-search");

		        $(".form-control.search-field").removeClass("visible-search");

		    }

	    });





	    $(".form-control.search-field").mouseover(function(){

	        $(this).removeClass("non-visible-search");

	         $(this).addClass("visible-search");

	         $('.search-button').addClass("visible-search-btn");

	    });

	    $(".form-control.search-field").mouseout(function(){

	    	if ($(this).val().length === 0 && !$(this).is(':focus')){

		        $(this).addClass("non-visible-search");

		        $(this).removeClass("visible-search");

		        $('.search-button').removeClass("visible-search-btn");

		    }

	    });







		// start up tooltips

		$('[data-toggle="tooltip"]').tooltip()



		// // define var's

		// var sections_timer 	= null;

		// var window_height 	= $(window).height();



		// // fix section heights

		// //setTimeout(function(){

		// 	$('section.fsh').each(function(){

		// 		var section = $(this),

		// 			section_height = section.outerHeight();





		// 		if (section_height < window_height) {

		// 			section.height(window_height);

		// 		}

		// 	});

		// //}, 1500);



		var showcase = $('#showcase-row');

		if(typeof showcase === 'undefined' || showcase.length == 0) {

			$('body').addClass('spacer-top');

		}



		// remove messages container

		var msgcontainer = $('#message-container');



		if (msgcontainer.length > 0) {

			setTimeout(function(){

				msgcontainer.remove();

			}, 10000);

		}



		$('#attachments_table').on('change', 'input[type="file"]', function(){

			var ff = $(this).parent().get(0);

			var table = $(ff).parent();



			var fullPath = $(this).val();

			var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));

			var filename = fullPath.substring(startIndex);

			if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {

				filename = filename.substring(1);

			}



			table.find('.filename').html(filename);

		});



		$('.scroll-page').on('click', function(){



			var parent 		= $(this).parent();

			var parent_top_position	= parent.offset().top;

			var parent_height	= parent.outerHeight();



			var scroll = window_height;



			if (window_height - (parent_top_position + parent_height) <= 0) {

				scroll = parent_top_position + parent_height

			}



			$('html, body').animate({scrollTop: scroll}, 600);

		});



		// fix readio buttos

		$('.form-control.radio').each(function(){

			var radios 		= $(this).find('input[type="radio"]');

			var active_radio 	= null;

			radios.each(function(){

				var r = $(this).attr('checked');

				if (typeof r !== typeof undefined && r !== false) {

					active_radio = $(this);

				}

			});



			if(active_radio !== null) {

				var id 		= active_radio.attr('id');

				var label 	= $(this).find('label[for="'+id+'"]');



				if (typeof label !== typeof undefined && label !== false) {

					label.addClass('active');

				}

			}

		});



		$('.form-control.radio').on('click', 'label', function(){

			var wrapper = $(this).parent();

			wrapper.find('label.active').removeClass('active');

			$(this).addClass('active');

		});



		// start video play in list

		$('.items-block .item.video').on('click', function(){

			var play = $(this);

			play.find('picture').hide();

			play.find('.card-label').hide();

			play.find('.card-img-overlay').hide();

			var player_wrapper = play.find('.embed-responsive');

			var player = player_wrapper.find('iframe');

			player_wrapper.show();

			player[0].src += '?autoplay=1';

		});



		fixSections($(window));

		msieversion();

	});



	var $window = $(window);



	// fix sections on scroll

	$window.on('scroll', function(){

		fixSections($(this));

	});



	$window.on('load', function(){

		var $body = $('body');

		var $window = $(this);



		if($body.height() < $window.height()) {

			var content_height = $('#content-wrapper').height();

			$('#content-wrapper').height(content_height + ($window.height() -$body.height() ));

		}

	});



	// fix sections

	function fixSections( window_ob ) {



		var window_offset = window_ob.scrollTop();

		var body = $('body');



		$('.fixed-section', '#header-wrapper').each(function(){



			var $this = $(this);



			if (body.hasClass('loged')) {

				$this.addClass('nonzero-position');

				$this.addClass('fix-to-top');

			} else {

				var element_offest = $this.offset().top;



				if (body.hasClass('home_page')) {

					if (element_offest > 0) {

						$this.addClass('nonzero-position');

					} else {

						$this.removeClass('nonzero-position');

					}

				} else {

					$this.addClass('nonzero-position');

				}



				if (typeof $this.attr('top-pos') !== 'undefined') {

					var element_offest = $this.attr('top-pos');

				}



				var element_height = $this.outerHeight();



				if (window_offset >= element_offest && !$this.hasClass('fix-to-top')) {



					// add pseudo element to avoid content jump

					if (element_offest > 0/* || !body.hasClass('home_page')*/) {

						$this.before('<div class="fix-top-pseudo" style="height:'+element_height+'px"></div>');

					}



					$this.addClass('fix-to-top');

					$this.attr('top-pos', element_offest);



				} else if (window_offset < element_offest && $this.hasClass('fix-to-top')) {



					$this.removeClass('fix-to-top');

					if (element_offest > 0 || !body.hasClass('home_page')) {

						$this.prev('.fix-top-pseudo').remove();

					}



				}

			}

		});



	}



	function msieversion() {



		var ua 		= window.navigator.userAgent;

		var msie 	= ua.indexOf("MSIE ");

		var body 	= $('body');



		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number

		{

			body.addClass('msie');

		}



		return false;

	}

})(jQuery);

