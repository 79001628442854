//window._ = require('lodash');
//import Popper from 'popper.js/dist/umd/popper.js';

try {
    window.$ = window.jQuery = require('jquery');
    require('jquery-validation');
  	//require('chosen-jquery-browserify');

  	//window.Popper = Popper;
  	require('bootstrap');

  	require('slick-carousel');

  	// require('./ajaxloader');
  	require('./scripts');
} catch (e) {
	console.log(e);
}

jQuery.noConflict();
